import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, About, Contact, LandTransportation, Terms, Privacy } from './pages';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import RouteScrollToTop from "./elements/RouteScrollToTop";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
    <RouteScrollToTop />
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/about' element={<About />} />
      <Route exact path='/contact' element={<Contact />} />
      <Route exact path='/landtransportation' element={<LandTransportation />} />
      <Route exact path='/terms' element={<Terms />} />
      <Route exact path='/privacy' element={<Privacy />} />
      {/* <Route exact path='/service-details' element={<ServiceDetails />} /> */}   
    </Routes>
    <ScrollToTop smooth color='#00a2ff' />
  </BrowserRouter>
  );
}

export default App;
