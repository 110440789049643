import React, {Fragment, Suspense} from "react";
import { Preloader } from "./../elements";
// Lazy loading and components import
const Navbar = React.lazy(() => import("../components/Navbar"));
const Banner = React.lazy(() => import("../components/Banner"));
const Features = React.lazy(() => import("../components/Features"));
const AboutUs = React.lazy(() => import("../components/AboutUs"));
const Services = React.lazy(() => import("../components/Services"));
const WhyUs = React.lazy(() => import("../components/WhyUs"));
const Counter = React.lazy(() => import("../components/Counter"));
const Trucks = React.lazy(() => import("../components/Trucks"));
const Footer = React.lazy(() => import("../components/Footer"));
const Shipment = React.lazy(() => import("../components/Shipment"));
const FooterBottom = React.lazy(() => import("../components/FooterBottom"));

const Home = () => {
    return (
        <>
        <Fragment>
        <Suspense fallback={<Preloader />}>

          {/* Navbar One */}
          <Navbar />

          {/* Banner One */}
          <Banner />
          {/* Feature One */}
          <Features />

          {/* About One */}


          {/* Service One */}
          <Services />

          {/* Why Choose Us One */}
          <WhyUs />

          {/* Counter One */}
          <Counter />
          {/* Shipment */}
          <div
          className="pd-top-240"
          > 
            <Shipment/>
          </div>
           
           {/* Footer One */}
           <Footer />

            {/* Footer Bottom One */}
            <FooterBottom />
          </Suspense>
      </Fragment>
        </>
    )
}

export default Home;