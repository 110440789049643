import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import { Navbar, Breadcrumb, ContactInner, Footer, FooterBottom } from "../components";
const Contact = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <Navbar />

          {/* Breadcrumb */}
          <Breadcrumb title={"CONTACT US"} />

          {/* Breadcrumb */}
          <ContactInner />

          {/* Footer One */}
          <Footer />

          {/* Footer Bottom One */}
          <FooterBottom />
        </Suspense>
      </Fragment>
    </>
  );
};

export default Contact;
