import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import { Navbar, Footer, FooterBottom, Breadcrumb, TermsInner } from "../components";
const Terms = () => {
  return (
    <>
    <Fragment>
    <Suspense fallback={<Preloader />}>
    <Navbar />
    <Breadcrumb title={"Terms & Conditions"} />
    <TermsInner />
    <Footer />
    <FooterBottom />
      </Suspense>
      </Fragment>
      </>
  );
};

export default Terms;
