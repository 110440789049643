import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottom = React.lazy(() =>
  import("../components/FooterBottom")
);
const Footer = React.lazy(() => import("../components/Footer"));
const Navbar = React.lazy(() => import("../components/Navbar"));
const LandTranspDetails = React.lazy(() =>
  import("../components/LandTranspDetails")
);
const LandTransportation = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>

          {/* Navbar One */}
          <Navbar />

          {/* Breadcrumb */}
          <Breadcrumb title={"Land Transportation"} />

          {/* Service Details Inner */}
          <LandTranspDetails />

          {/* Footer One */}
          <Footer />

          {/* Footer Bottom One */}
          <FooterBottom />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default LandTransportation;
