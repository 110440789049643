import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import { Navbar, Footer, FooterBottom, Breadcrumb, PrivacyInner } from "../components";
const Privacy = () => {
  return (
    <>
    <Fragment>
    <Suspense fallback={<Preloader />}>
    <Navbar />
    <Breadcrumb title={"Terms & Conditions"} />
    <PrivacyInner />
    <Footer />
    <FooterBottom />
      </Suspense>
      </Fragment>
      </>
  );
};

export default Privacy;
