import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import { Navbar, AboutUs, Counter, Logistics, Footer, FooterBottom, Breadcrumb } from "../components";
const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <Navbar />

          {/* Breadcrumb */}
          <Breadcrumb title={"ABOUT US"} />

          {/* About One */}
          <div className='pd-top-120 pd-bottom-120'>
            <AboutUs />
          </div>

          {/* Counter One */}

          <div className='fact-area'>
            <Counter />
          </div>
          <Logistics />
          {/* Team One */}
          {/* <div className='pd-bottom-80'>
            <Team />
          </div> */}

          {/* Footer One */}
          <Footer />

          {/* Footer Bottom One */}
          <FooterBottom />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;
